html, body{ height:100%; }
body{ perspective:1000px; overflow:hidden; }
div.logo{
  width: 500px;
  height: 500px;
  background: url("./logo-lvs.jpeg") 0 0 no-repeat;
  background-size: contain;
  animation: rotation 3s infinite linear;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

@keyframes rotation {
  100%{ transform:rotatey(360deg); }
}